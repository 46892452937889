import React from 'react'
import styled from 'styled-components'
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import CallToAction from "../components/CallToAction"
import { TwoColumns } from "../components/Grid"
import AboutVission from "../components/Icons/AboutVission"
import AboutMission from "../components/Icons/AboutMission"
import {SectionPage, SectionHeading, SectionDescription, ExploreMore, ExploreItemMore, Container} from "../components/Section"
import PrimaryButton from "../components/Button/PrimaryButton"
import SecondaryButton from "../components/Button/SecondaryButton"
import SliderReview from "../components/ReviewWdiget/slider"

import AboutHero from "../sections/About/AboutHero"
import HistorySection from "../sections/About/HistorySection"
import HowWorks from "../sections/About/HowWorks"
import FeaturesSection from "../sections/Home/FeaturesSection"
import PeoplesSection from "../sections/About/PeoplesSection"

const HalfSection = styled.div`
	background: ${props=>props.bg};	
	padding:30px;
	text-align:center;
	@media (min-width:992px) {		
		padding:40px;
	}
	@media (min-width:1200px) {
		padding:60px;		
	}
	@media (min-width:1600px) {
		padding:80px;
	}
	& h2 {
		color: #fff;
	}
	& p {
		color: #fff;
		max-width:750px;
		width:100%;
		margin:0 auto;
	}
`
const AboutPage = (pageData) =>{
	const PeopleData = pageData.data.allContentfulPeople
	const AboutHeroData = pageData.data.contentfulAbout.aboutHero
	const AboutHistoryData = pageData.data.contentfulAbout.aboutHistory
	const AboutVisionData = pageData.data.contentfulAbout.aboutMissionVission
	const AboutHowWorksData = pageData.data.contentfulAbout.aboutHowWorks
	const AboutUspsData = pageData.data.contentfulAbout.aboutUsps
	const AboutPeopleData = pageData.data.contentfulAbout.aboutPeople
	return(
		<Layout location={pageData.location}>
      <SEO title={pageData.data.contentfulAbout.metaTitle} description={pageData.data.contentfulAbout.metaDescription} />
      <AboutHero data={AboutHeroData} />
      <HistorySection data={AboutHistoryData} />
      <TwoColumns>
      	<HalfSection bg="#1E4156">
      		<figure><AboutMission /></figure>
      		<h2>Our Vision</h2>
      		<p>{AboutVisionData.features[0].description}</p>
      	</HalfSection>
      	<HalfSection bg="#010920">
      		<figure><AboutVission /></figure>
      		<h2>Our Mission</h2>
      		<p>{AboutVisionData.features[1].description}</p>
      	</HalfSection>
      </TwoColumns>
      <FeaturesSection data={AboutUspsData} />
      <PeoplesSection peopleData={PeopleData} data={AboutPeopleData} />
      <HowWorks data={AboutHowWorksData} />
	  <SectionPage
		ept="80px"
		epb="80px"
		xpt="60px"
		xpb="60px"
		pt="40px"
		pb="40px"
		bg="#fff"
		className="section-review"
		>
			<Container>
				<SectionHeading textAlign="center">Customer Testimonials and Reviews</SectionHeading>
				<SectionDescription><p>Read what real customers have to say about their experience with Probuilt Steel Buildings.</p></SectionDescription>
				<SliderReview />
				<ExploreMore mt="20px" ml="-5px" mr="-5px">
					<ExploreItemMore pl="5px" pr="5px">
						<Link to="/reviews/"><SecondaryButton text="Load More" /></Link>
					</ExploreItemMore>
					<ExploreItemMore pl="5px" pr="5px">
						<a href="https://g.page/r/CYAtY7rhWYH_EAI/review" target="_blank" rel="noreferrer"><PrimaryButton text="Write a Review" /></a>
					</ExploreItemMore>
				</ExploreMore>
			</Container>
		</SectionPage>
      <CallToAction />
    </Layout>
	)
}

export default AboutPage

export const pageQuery = graphql`
  query AboutPageQuery {
  	contentfulAbout {
	    title
	    metaTitle
	    metaDescription
	    aboutHero {
	      title
	      description {
	        childMarkdownRemark {
	          html
	        }
	      }
	    }
	    aboutHistory {
	      title
	      description {
	        childMarkdownRemark {
	          html
	        }
	      }
	    }
	    aboutMissionVission {
	      features {
	        image
	        name
	        description
	      }
	    }
	    aboutHowWorks {
	      title
	      description {
	        childMarkdownRemark {
	          html
	        }
	      }
	      features {
	      	name
	      	description
	      }
	    }
	    aboutUsps {
	      title
	      description {
	        childMarkdownRemark {
	          html
	        }
	      }
	      features {
	      	title
	      	image
	        description
	      }
	    }
	    aboutPeople {
	      title
	      description {
	        childMarkdownRemark {
	          html
	        }
	      }
	    }
	  }
	  allContentfulPeople {
	    edges {
	      node {
	        name
	        role
	        description {
	          description
	        }
	        image {
	          fluid(maxWidth: 300, quality: 100) {
	            base64
	            sizes
	            src
	            srcSet
	            srcSetWebp
	            srcWebp
	            aspectRatio
	          }
	        }
	      }
	    }
	  }
	}
`