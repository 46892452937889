import React from 'react'
import styled from 'styled-components'
import { StaticImage } from "gatsby-plugin-image"
import { SectionPage, Container, SectionHeading, MarkdownContent } from "../../components/Section"

const Grid = styled.div`
	display:flex;
	flex-wrap:wrap;
	align-items: center;
	margin:0 -10px;
`
const GridItem = styled.div`
	position:relative;
	width:100%;
	padding:0 15px;
	@media (min-width:992px) {
		max-width:50%;
		flex:0 0 50%;
	}
`

class HistorySection extends React.Component {
	render(){
    const { data } = this.props
		return(
			<SectionPage ept="80px" epb="80px" xpt="60px" xpb="60px" pt="40px" pb="40px" bg="#fff" className="section-know">
				<Container>
					<Grid>
						<GridItem>
							<StaticImage src="../../images/about-probuilt.png" alt="Probuilt Steel Buildings" />							
						</GridItem>
						<GridItem>
							<SectionHeading>{data.title}</SectionHeading>
							<MarkdownContent dangerouslySetInnerHTML={{__html: data.description.childMarkdownRemark.html}} />
						</GridItem>
					</Grid>
				</Container>
		</SectionPage>
		)
	}
}

export default HistorySection