import React, { Component } from "react"
import { SectionPage, SectionPageTitle, Container } from "../../components/Section"

class AboutHero extends Component {
  render() {
    return (
      <SectionPage
        ept="160px"
        epb="40px"
        xpt="160px"
        xpb="40px"
        pt="100px"
        pb="40px"
        bg="#1E4156"
      >
        <Container>
          <SectionPageTitle color="#fff" textAlign="center" mb="0" textTransform="uppercase">{this.props.data.title}</SectionPageTitle>
          {/* {!!this.props.data.description && (
            <MarkdownContent
              textAlign="center"
              dangerouslySetInnerHTML={{
                __html: this.props.data.description.childMarkdownRemark.html,
              }}
            />
          )} */}
        </Container>
      </SectionPage>
    )
  }
}


export default AboutHero