import React from 'react'
import styled from 'styled-components'
import StepPoint from "../../components/Icons/StepPoint"
import { MarkdownContent, SectionPage, Container, SectionHeading } from "../../components/Section"

const StepGrid = styled.div`
	margin-top: 40px;
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	@media(min-width: 768px) {
		padding:0 30px;
		grid-template-columns: repeat(3, 1fr);
	}
`

const Step = styled.div`
	margin: 64px 50px;
	position: relative;
	min-height: 200px;
	& h3 {
		position: absolute;
		top: 30%;
		background: white;
		z-index: 10;
		margin-left: -85px;
		font-size: 54px;
		line-height: 64px;
		color: #E8EBEE;
	}
	& h4 {
		font-size: 24px;
		line-height: 34px;
		color: #062C44;
		margin-bottom: 16px;
	}
	& p {
		font-size: 16px;
		line-height: 26px;
		color: #010920;
	}
	@media(max-width: 768px) {
		margin: 34px 20px 0 30px;
		& h3 { 
			margin-left: -60px;
			font-size: 34px !important;
			line-height: 44px;
		}
	}
`

const FirstContainer = styled.div`
	border-left: 4px solid #E8EBEE;
	border-bottom: 4px solid #E8EBEE;
	position: relative;
	& svg {
		position: absolute;
		top: -15px;
		left: -20px;
	}
	@media(max-width: 768px) { 
		border-bottom: 0px;
		margin-left: 20px;
		& svg {
			left: -20px;
			top: -30px;
		}
	}
`
const SecondContainer = styled.div`
	border-left: 4px solid #E8EBEE;
	border-top: 4px solid #E8EBEE;
	@media(max-width: 768px) { 
		border-top: 0px;
		margin-left: 20px;
	}
`
const ThirdContainer = styled.div`
	border-left: 4px solid #E8EBEE;
	position: relative;
	& svg {
		position: absolute;
		bottom: 0px;
		left: -20px;
	}

	@media(max-width: 768px) {
		margin-left: 20px;
		& svg {
			bottom: -30px;
		}
	}
`
class HowWorks extends React.Component {
	render(){
		const { data } = this.props
		return(
			<SectionPage
				  ept="80px"
				  epb="80px"
				  xpt="60px"
				  xpb="60px"
				  pt="40px"
				  pb="40px"
				  bg="#F4FBFF"
				  className="section-step"
				>
				  <Container>
					<SectionHeading textAlign="center">{data.title}</SectionHeading>
					<MarkdownContent textAlign="center" dangerouslySetInnerHTML={{__html: data.description.childMarkdownRemark.html}} />
					<StepGrid>
						<FirstContainer>
							<StepPoint />
							{
								data.features.map((node, i)=>{
									if (i < 2) {
										return(
											<Step>
												<h3>0{i+1}</h3>
												<h4>
													{node.name}
												</h4>
												<p>
													{node.description}
												</p>
											</Step>
										)
									}
									return(<></>)
								})
							}
						</FirstContainer>
						<SecondContainer>
							{
								data.features.map((node, i)=>{
									if (i > 1 && i < 4) {
										return(
											<Step key={i}>
												<h3>0{i+1}</h3>
												<h4>
													{node.name}
												</h4>
												<p>
													{node.description}
												</p>
											</Step>
										)
									}
									return(<></>)
								})
							}
						</SecondContainer>
						<ThirdContainer>
							<StepPoint />
							{
								data.features.map((node, i)=>{
									if (i>3) {
										return(
											<Step>
												<h3>0{i+1}</h3>
												<h4>
													{node.name}
												</h4>
												<p>
													{node.description}
												</p>
											</Step>
										)
									}
									return(<></>)
								})
							}
						</ThirdContainer>
					</StepGrid>
				</Container>
			</SectionPage>
		)
	}
}

export default HowWorks
